<template>
  <vx-card
    :title="this.surveyAnswerID ? 'Edit Survey Answer' : 'Create Survey Answer'"
  >
    <div class="vx-row mb-6" style="width: 50%">
      <vs-button
        class="ml-4 mt-2"
        color="danger"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="handleBack()"
        >Back</vs-button
      >
    </div>
    <div class="vx-row mb-6" style="width: 50%" v-if="this.surveyAnswerID">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Code</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input :value="this.code" :readonly="true"></vs-input>
      </div>
    </div>
    <!-- <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Name</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input v-model="name" width="100%" />
      </div>
    </div> -->

    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Description</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-textarea v-model="description" width="100%" />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col sm:w-1/1 w-full mb-base">
        <vs-table :data="this.surveyAnswerLines" :border="1">
          <template slot="thead">
            <vs-th width="5%">No</vs-th>
            <vs-th width="70%">Option</vs-th>
            <vs-th width="10%"></vs-th>
            <vs-th width="15%"></vs-th>
          </template>
          <template slot-scope="{ data }">
            <draggable :list="surveyAnswerLines" tag="tbody">
              <tr :data="tr" :key="index" v-for="(tr, index) in data">
                <td class="td vs-table--td">
                  {{ index + 1 }}
                </td>
                <td class="td vs-table--td">
                  <vs-input
                    v-model="surveyAnswerLines[index].value"
                    class="w-full"
                  />
                </td>
                <td class="td vs-table--td" style="text-align: center">
                  <arrow-up-down-bold :size="15" />
                </td>
                <td class="td vs-table--td" style="text-align: center">
                  <div class="vx-input-group flex">
                    <vs-button
                      v-if="surveyAnswerLines.length > 1"
                      @click.stop="removeRow(index)"
                      size="small"
                      color="danger"
                      icon-pack="feather"
                      icon="icon-trash"
                      style="margin-right: 5px"
                      title="Remove Row"
                    />

                    <vs-button
                      v-if="index == surveyAnswerLines.length - 1"
                      @click.stop="addRow()"
                      size="small"
                      color="success"
                      icon-pack="feather"
                      icon="icon-plus"
                      title="Add Row"
                    />
                  </div>
                </td>
              </tr>
            </draggable>
          </template>
        </vs-table>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/1 w-full">
        <div
          style="
            position: absolute;
            left: 50%;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
          "
        >
          <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
          <vs-button
            class="ml-4 mt-2"
            type="border"
            color="danger"
            v-on:click="handleBack"
            >Back</vs-button
          >
        </div>
      </div>
    </div>
  </vx-card>
</template>
<script>
import ArrowUpDownBold from "vue-material-design-icons/ArrowUpDownBold.vue";
import PlusBox from "vue-material-design-icons/PlusBox.vue";

import draggable from "vuedraggable";

export default {
  components: {
    draggable,
    ArrowUpDownBold,
    PlusBox,
  },
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        baseUrl: "/api/sfa/v1/survey-answer",
        code: "",
        name: "-",
        description: "",
        surveyAnswerLines: [],
        surveyAnswerID: null,
      };
    },
    handleBack() {
      this.$router.push({
        name: "survey-answer",
      });
    },
    handleSubmit() {
      if (!this.name) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Name is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }

      if (this.surveyAnswerLines.length == 0) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Option is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }

      let cek = this.surveyAnswerLines.filter((d) => {
        return d.value == "";
      });
      if (cek.length > 0) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Option is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }

      let body = {
        name: this.name,
        description: this.description,
        survey_answer_lines: this.surveyAnswerLines,
      };

      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();

          let res = null;
          if (this.surveyAnswerID) {
            res = this.$http.put(
              this.baseUrl + "/" + this.surveyAnswerID + "/update",
              body
            );
          } else {
            res = this.$http.post(this.baseUrl + "/create", body);
          }

          res
            .then((resp) => {
              if (resp.code == 200) {
                this.$vs.notify({
                  color: "success",
                  title: "Success",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });

                this.$vs.loading.close();
                setTimeout(this.handleBack(), 2000);
              } else {
                this.$vs.loading.close();
                this.$vs.notify({
                  color: "danger",
                  title: "Error",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              console.log(error);
            });
        }
      });
    },
    getSurveyAnswer() {
      if (this.surveyAnswerID) {
        this.$vs.loading();
        this.$http
          .get(this.baseUrl + "/" + this.surveyAnswerID + "/edit")
          .then((resp) => {
            if (resp.code == 200) {
              this.code = resp.data.code;
              this.name = resp.data.name;
              this.description = resp.data.description;
              this.surveyAnswerLines = resp.data.survey_answer_lines;
            }
            this.$vs.loading.close();
          });
      } else {
        this.optionPersonal = [];
      }
    },
    addRow() {
      this.surveyAnswerLines.push({
        survey_answer_line_id: null,
        value: "",
        with_text: false,
      });
    },
    removeRow(index) {
      this.surveyAnswerLines.splice(index, 1);
    },
  },
  created() {},
  mounted() {
    this.surveyAnswerID = this.$route.params.id;
    if (!this.surveyAnswerID) {
      this.surveyAnswerLines = [
        {
          survey_answer_line_id: null,
          value: "",
          with_text: false,
        },
      ];
    }
  },
  watch: {
    surveyAnswerID() {
      this.getSurveyAnswer();
    },
    surveyAnswerLines(val) {
      console.log(val);
    },
  },
};
</script>

